import { gql } from '../generated';

gql(`
  fragment Application on HealthApplication {
    id
    applicationContext
    applicationOrigin
    applicationVersionNum
    asyncStatus
    coverageState
    coverageYearNumber
    createdOn
    eligibilityLastFetched
    hasSubmittedPermissions
    householdIncome
    insuranceApplicationIdentifier
    lastUpdated
    lastUsedRoute
    lastUserUpdate
    pathwayType
    planPremium
    planPremiumEffective
    status
    isDeleted
    aptcHouseholdAmount
    isEligibilityDetermined
  }
`);

gql(`
  fragment HouseholdInfo on HealthApplication {
    coverageState
    coverageYearNumber
    zip
    countyfips
    maritalStatus
    numTaxDependents
    isSelfCoverage
    isSpouseCoverage
    numDependentsRequestCoverage
    householdIncome
  }
`);

gql(`
  fragment Agreements on HealthApplication {
    isRenewalAgreement
    renewEligibilityYearQuantity
    isMedicaidRequirementAgreement
    isAbsentParentAgreement
    isTerminateCoverageOtherMecFoundAgreement
    isChangeInformationAgreement
    isTaxCreditReconciled
    isReuseTaxDataPermission
    isPenaltyOfPerjuryAgreement
    isNonIncarcerationAgreement
    signatureText
    signatureDate
    signatureType
    aptcAgreement
    aptcSignature
  }
`);

gql(`
  fragment Screening on HealthApplication {
    isRequestingFinancialAssistance
    screening {
      failedScreeners

      # phase 3
      areAllSameAddress
      areDependentsLivingWithParentNotOnTaxReturn
      isClaimingAllDependentsOnTax
      isCoveredThroughJobOrCobra
      isDependentSingleChild25OrYounger
      isIndianOrAlaskan
      isOfferedIchra
      isPlanningToFileTax
      isRequestingFinancialAssistance
      isResponsibleForChildNotOnTaxReturn
      areDependentsChildOrStepchildSingleUnder26

      # phase 2
      areApplicationsCitizens
      isAnyoneFullTimeStudent1822
      isApplicationNameSameAsSsn
      isCanProvideSsn
      isFormerFosterCareAndUnder25
      isIncarcerated
      isNaturalizedOrDerivedCitizenship
      isPregnantOrHadChildLast60Days
      isStepchildOrGrandchild
    }
  }
`);

gql(`
  fragment FullMember on ApplicationMember {
    id
    isRequestingCoverage
    relation
    givenName
    middleName
    familyName
    nameSuffix
    age
    sex
    dob
    email
    ssn
    fullSSN
    filerRelation
    spouseRelation
    isSmoker
    isPregnant
    isParent
    birthCountry
    writtenLanguageType
    spokenLanguageType
    contactMethod
    mobileNotificationPhoneNumber
    race
    otherRaceText
    isHispanicOrigin
    ethnicity
    otherEthnicityText
    sexAssignedAtBirth
    sexAssignedAtBirthOtherText
    gender
    genderDifferentTermText
    sexualOrientation
    sexualOrientationDifferentTermText

    isLostCoverageLast60Days
    coverageLostDate
    isWillLoseCoverageNext60Days
    coverageLostDateFuture
    isMarriedLast60Days
    isGainDependent
    isMoved
    isReleasedFromIncarceration
    isNoChanges
    whenMarried
    isQualifyingCoverageLast60Days
    isLivedOutsideUsLast60Days
    whenRelocated
    isMovedFromOutsideUs
    previousZipcode
    whenBecomeADependent
    whenReleasedFromIncarceration
    hasCoverage60DaysBeforeMarriage
    hasLivedOutsideUSLast60DaysBeforeMarriage
    previousCountyLivedName
    hasCoverageLast60DaysBeforeRelocation
    previousStateCode
    isBlindOrDisabled
    isLongTermCare
    incomeThisMonth
    isDeniedMedicaidLast90Days
    isMedicaidEndingSoon
    isMedicaidDenied
    medicaidDeniedDate
    isAppliedDuringOeOrLifeChange
    isEnrolledInHealthCoverage
    parent1WeeklyWorkHourQuantity
    parent2WeeklyWorkHourQuantity
    isUnpaidBill
    isCoveredDependentChild
    isCoverageEnded
    isStateHealthBenefit
    coverageEndedReason
    coverageEndedReasonText
    applicationMemberID
    isAttestedHeadOfHousehold
    isTaxFiler
    taxReturnFilingStatusType
    isClaimsDependent
    isClaimingTaxFilerNotOnApplication
    hasAbsentParent
    babyDueQuantity
    isFosterCare1825
    fosterCareState
    isMedicaidDuringFosterCare
    fosterCareEndAge
    incarcerationType
    isFullTimeStudentStatus
    isCitizen
    isNaturalizedCitizen
    isLawfulPresenceStatus
    lawfulPresenceGrantDate
    whenLawfulPresence
    isGainedLawfulPresence
    hasNoAlienNumber
    hasLivedInUS5Years
    isMedicaidDeniedDueToImmigration
    isImmigrationStatusChangeSinceDenied
    hasImmigrationStatusFiveYear
    isVeteran
    isVeteranSelf
    nonMemberVeteranRelationshipTypes
    isNoHomeAddress
    isTemporaryOutOfState
    personTrackingNumber
    isTaxDependent
    isRequestingMedicaidDetermination
    preliminaryMedicaidStatus
    preliminaryEmergencyMedicaidStatus
    preliminaryEmergencyMedicaidStatusReason
    preliminaryAPTCStatus
    preliminaryCHIPStatus
    qhpResidencyStatus
    qhpLawfulPresenceStatus
    chipWaitingPeriodStatus
    chipWaitingPeriodStatusReason
    ssnStatus
    ssnStatusReason
    citizenshipStatus
    citizenshipStatusReason
    fiveYearBarStatus
    fiveYearBarStatusReason
    incarcerationStatus
    incarcerationStatusReason
    preliminaryQHPStatus
    isInfoChangeSinceMedicaidEnded
    isMedicaidEnd
    medicaidEndDate
    isEnrolledInIchra
    isOfferedIchra
    hasDeductions
    lastTobaccoUseDate

    canRequestFullMedicaidDetermination
    enrollmentGroupID
    maritalStatus
    whenLostCoverage
    mailingAddress {
      street1
      street2
      city
      state
      zip
      zip4
      country
      countyfips
    }
    homeAddress {
      street1
      street2
      city
      state
      zip
      zip4
      country
      countyfips
    }
    phone {
      number
      type
      ext
    }
    secondaryPhone {
      number
      type
      ext
    }
    annualTaxIncome {
      isVariableIncome
      isUnknownIncome
      incomeAmount
      isIncomeLessExplained
      taxHouseholdIncomeDiscrepancyDescriptionText
      taxHouseholdIncomeDifferenceReasonType
      variableIncomeDescriptionText
      attestedAnnualizedAPTCIndividualIncomeAmount
      annualIncomeExplanationRequired
      hasReceivedUnemploymentCompensation
    }
    incomeSources {
      type
      incomeAmount
      incomeFrequencyType
      employerPhoneNumber
      employerIdentificationNumber
      averageWeeklyWorkHours
      averageWeeklyWorkDays
      selfEmploymentIncomeDescription
      incomeDescription
      expirationDate
      tribalIncomeAmount
      isEstimatedForAptc
      incomeDifferenceReason
      otherIncomeDifferenceReason
      otherIncomeDescription
      employerName
      otherDeductionDescription
      jobIncomeExplanationRequired
      educationExpenses
      incomeID
      employerAddress {
        street1
        street2
        city
        state
        zip
        zip4
        country
        countyfips
      }
      incomeStateExchangeFields {
        isPaidBeforeJan1
        dateIncomeBegan
        isPresentlyEmployed
        dateLastGotIncome
        isExpectedToEndIn4Months
        dateIncomeEnded
        incomeName
      }
    }
    deductions {
      incomeSourceType
      incomeAmount
      incomeFrequencyType
      otherDeductionDescription
      incomeID
    }
    otherCoverages {
      insuranceMarketType
      insurancePolicyNumber
      insurancePolicyMemberID
      insurancePlanName
    }
    ssnAlternateName {
      firstName
      middleName
      lastName
      prefix
      suffix
    }
    lawfulPresenceDocumentation {
      documentType
      documentExpirationDate
      alienNumber
      employmentAuthorizationCategoryIdentifier
      sevisID
      i94Number
      passportNumber
      naturalizationCertificateNumber
      citizenshipNumber
      isNoIdentifiersProvided
      otherDocumentTypeText
      passportIssuingCountry
      cardNumber
      documentAlternativeName {
        firstName
        middleName
        lastName
        prefix
        suffix
      }
    }
    hraOffers {
      hraType
      startDate
      endDate
      noticeDate
      isEnrolledInOfferFromSameEmployer
      isEnrolledInOfferFromSameEmployerUntilStartDate
      primaryInsuredMemberIdentifier
      isPrimaryInsuredMemberNotInTaxHh
      employeeSelfOnlyOfferAmount
      employeeSelfOnlyOfferFrequencyType
    }
    memberStateExchangeFields {
      isPrimary
      isMedicaidPartAEligible
      isPrimaryTaxFiler
      isRequiredToFileTaxes
      isClaimedAsTaxDependent
    }
  }  
`);

gql(`
  fragment FullApplication on HealthApplication {
    id
    status
    ...HouseholdInfo
    ...Screening
    ...Agreements


    incomeExplanationRequiredIndicator
    incomeExplanationRequiredReason
    incomeExplanationDocumentedAmount
    voterRegistrationAnswerType

    allMembers {
      ...FullMember
    }
  }
`);

export const CREATE_APPLICATION = gql(`
  mutation CreateApplication($input: UpsertHealthApplicationInput!, $personID: ID!) {
    upsertHealthApplicationV2(input: $input, personID: $personID) {
      ...Application
    }
  }
`);

export const USER_APPLICATIONS = gql(`
  query UserApplications($id: ID!, $input: ApplicationsInput!) {
    viewerTwo(id: $id) {
      id
      health {
        applications(input: $input) {
          ...Application
        }
      }
    }
  }
`);

export const GET_APPLICATION = gql(`
  query GetApplication($personId: ID, $appId: ID!) {
    viewerTwo(id: $personId) {
      id
      health {
        application(id: $appId) {
          ...FullApplication
        }
      }
    }
  }
`);
