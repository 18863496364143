import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FullModal, RowGroup, IRow } from 'src/web/components';
import { GET_APPLICATION } from 'src/data';
import { DebugTree } from 'src/web/inspectors';

const StyledTile = styled.div`
  position: absolute;
  right: 24px;
  left: 24px;
  bottom: 200px;
  top: calc(24px + var(--navHeight));
  opacity: 1;
  background: transparent url(${p => p.src}) no-repeat center/contain;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 5px 20px rgba(6, 7, 10, 0.15));
  cursor: pointer;
  transform: rotate(${p => p.rotation}deg);
  will-change: transform;
  transition: var(--transition);
  div {
    will-change: transform;
    transform: rotate(${p => -p.rotation}deg);
    transition: var(--transition);
  }
`;

interface HealthApplicationModalProps {
  appId: string;
  personId: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const StyledRoute2 = styled.div`
  padding: 6px 24px 6px 32px;
  display: flex;
  align-items: center;
  font-size: var(--fp);
  font-weight: ${p => (p.active ? 600 : 400)};
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  transition: 0.25s var(--transitionCurve);
  cursor: var(--cursor);
  will-change: color font-weight;
  border-radius: 2px;
  color: ${p => (p.active ? 'var(--c-cerise-0)' : p.visited ? 'var(--c-fg-0)' : 'var(--c-fg-1)')} !important;
  &:hover {
    background: ${p => (p.visited ? 'var(--c-border-light)' : '')};
    color: ${p => (p.visited ? 'var(--c-cerise-0)' : '')} !important;
    .dot {
      background: ${p => (p.visited ? 'var(--c-cerise-0)' : '')};
      transform: ${p => (p.visited ? 'scaleY(1)' : '')};
    }
  }
`;

/**
 * helper function to remove __typename from input
 */
export function removeTypename<T>(input: T): T {
  if (Array.isArray(input)) {
    // process arrays recursively
    return (input.map(removeTypename) as unknown) as T;
  } else if (typeof input === 'object' && input !== null) {
    // process objects by removing __typename and processing other keys recursively
    const { __typename, ...rest } = input as Record<string, any>;
    return Object.fromEntries(Object.entries(rest).map(([key, value]) => [key, removeTypename(value)])) as T;
  }
  // otherwise, return values
  return input;
}

export function HealthApplicationModal({ appId, personId, isOpen, onRequestClose }) {
  const { loading, data } = useQuery(GET_APPLICATION, {
    variables: { appId, personId },
  });

  const application = useMemo(() => {
    return removeTypename(data?.viewerTwo?.health?.application);
  }, [data]);

  return (
    <FullModal
      isOpen={isOpen}
      hasChange={false}
      onRequestClose={onRequestClose}
      title="Health Application"
      subtitle={application?.coverageState}
      sidebarItem={
        <RowGroup title="Plan Details" borderless>
          <IRow label="Coverage Year" value={application?.coverageYearNumber} />
          <IRow label="Coverage State" value={application?.coverageState} />
        </RowGroup>
      }
    >
      <div className="tree">
        <DebugTree data={application} sort={false} />
      </div>
    </FullModal>
  );
}
