import { gql } from '@apollo/client';
import {
  FRAGMENT_HEALTH_APP,
  FRAGMENT_HEALTH_APP_LONG,
  FRAGMENT_HEALTH_POLICY,
  FRAGMENT_HEALTH_POLICY_LONG,
  FRAGMENT_HEALTH_PLAN,
  FRAGMENT_HEALTH_APP_PLANS,
  FRAGMENT_TASK,
  FRAGMENT_PERSON,
} from './fragments';

export const WHOAMI = gql`
  query WhoAmI {
    viewer {
      id
      user {
        ...PersonFragment
      }
    }
  }
  ${FRAGMENT_PERSON}
`;

export const NUM_TASKS = gql`
  query NumTasks($input: ListTasksInput!) {
    tasks(input: $input) {
      pageInfo {
        totalItems
      }
      edges {
        id
      }
    }
  }
`;

export const TASKS = gql`
  query Tasks($input: ListTasksInput!) {
    tasks(input: $input) {
      pageInfo {
        totalItems
      }
      edges {
        id
        priority
        createdOn
        lastUpdated
        status
        notes
        type
        isAssignment
        user {
          id
          email
          nickname
          givenName
          familyName
          avatarImage
          createdOn
        }
        assignee {
          id
          email
          nickname
          givenName
          familyName
          avatarImage
        }
        personReference {
          id
          givenName
          familyName
          email
          signupCode
          avatarImage
          healthApplicantEmail
          healthApplicantGivenName
          healthApplicantFamilyName
        }
        ... on PartnerHealthTask {
          id
          enrollment {
            id
            status
            year
            actionsNeeded
          }
        }
        ... on TaxPaymentTask {
          id
          taxPayment {
            id
            createdOn
            status
            amount

            yearOfLastCompletedFiling
            isAuto
            addressOfLastCompletedFiling {
              street1
              street2
              city
              state
              zip
            }
            person {
              ...PersonFragment
              filingStatus
              dob
              legalAddress {
                street1
                street2
                city
                state
                zip
              }
            }
            adminUser {
              ...PersonFragment
            }
          }
        }
        ... on SubscriptionTask {
          subscription {
            id
          }
        }

        ... on HealthEnrollmentTask {
          enrollment {
            id
            status
            year
            actionsNeeded
          }
        }
      }
    }
  }
  ${FRAGMENT_PERSON}
`;

export const TASK = gql`
  query Task($input: GetTasksInput!, $userId: ID) {
    viewer(id: $userId) {
      id
      savingsAccount {
        id: providerUserId
        accountNumber
        routingNumber
      }
      taxGoal: goal(slug: "taxes") {
        id
        ... on TaxGoal {
          FilingName
          YearOfLastCompletedFiling
          AddressOfLastCompletedFiling {
            street1
            street2
            city
            state
            zip
          }
        }
      }
    }
    task(input: $input) {
      id
      priority
      createdOn
      lastUpdated
      isAssignment
      priority
      status
      notes
      type
      isAssignment
      user {
        id
        email
        nickname
        givenName
        familyName
        avatarImage
        signupCode
      }
      assignee {
        id
        nickname
        givenName
        familyName
        avatarImage
      }
      ... on TaxPaymentTask {
        id
        notes
        taxPayment {
          id
          createdOn
          isAuto
          notes
          confirmationCode
          status
          amount
          timeframe
          spouseSSN
          filingName
          filingStatus
          confirmationEmailAddress
          yearOfLastCompletedFiling
          addressOfLastCompletedFiling {
            street1
            street2
            city
            state
            zip
          }

          adminUser {
            ...PersonFragment
          }
        }
      }
      ... on SubscriptionTask {
        id
        subscription {
          id
        }
      }
      ... on ExpertSetupTask {
        id
      }
      ... on HealthEnrollmentTask {
        id
        enrollment {
          id
          status
          year
          # isOpenEnrollment
          enrollmentType

          baseMembers {
            id
            givenName
            familyName
          }
          ...HealthApplicationFragment
          ...HealthPolicyFragment
        }
      }
      ... on PartnerHealthTask {
        id
        enrollment {
          id
          status
          year
          baseMembers {
            id
            givenName
            familyName
          }

          ...HealthPolicyFragment
        }
      }
    }
  }
  ${FRAGMENT_HEALTH_APP}
  ${FRAGMENT_HEALTH_POLICY}
  ${FRAGMENT_PERSON}
`;

export const UPSERT_TASK = gql`
  mutation upsertTask($input: UpsertTaskInput!) {
    upsertTask(input: $input) {
      id
      user {
        ...PersonFragment
      }
      ...TaskFragment
    }
  }
  ${FRAGMENT_TASK}
  ${FRAGMENT_PERSON}
`;

export const EDE_ENROLLMENT = gql`
  query HealthEnrollment(
    $userId: ID
    $appId: ID! # $policyId: ID!
  ) {
    viewerTwo(id: $userId) {
      id
      health {
        applications(input: { includeDeleted: true }) {
          id
          coverageYearNumber
          coverageState
          coverageYearNumber
          status
        }
        policies(input: {}) {
          id
          carrier
          planName
          premium
          premiumEffective
          aptcAmount
          policyStatus
          startDate
          endDate
          agentID
        }
        application(id: $appId) {
          id
          coverageYearNumber
          coverageState
          coverageYearNumber
          householdIncome
          # numTaxDependents
          lastUpdated
          lastUserUpdate
          signatureText
          signatureDate
          status
          pathwayType
          selectedPlanID
          ... on HealthApplication {
            id
            creationOriginType
            submissionOriginType
            createdOn
            applicationContext
            eligibilityLastFetched
            # screeningPhase
            lastUsedRoute
            applicationOrigin
            isEligibilityDetermined
            hasSubmittedPermissions
            appliedCSRLevel
            aptcAgreement
            signatureText
            aptcSignature
            areAllSameAddress
            areDependentsLivingWithParentNotOnTaxReturn
            incomeExplanationDocumentedAmount
            incomeExplanationRequiredIndicator
            incomeExplanationRequiredReason
            insuranceApplicationIdentifier
            isAbsentParentAgreement
            isChangeInformationAgreement
            isClaimingAllDependentsOnTax
            isCoveredThroughJobOrCobra
            isDependentSingleChild25OrYounger
            isEligibilityDetermined
            isIndianOrAlaskan
            isMedicaidRequirementAgreement
            isNonIncarcerationAgreement
            isPenaltyOfPerjuryAgreement
            isPlanningToFileTax
            isPrefill
            isRenewalAgreement
            isRequestingFinancialAssistance
            isResidesInApplicationState
            isResponsibleForChildNotOnTaxReturn
            isReuseTaxDataPermission
            isSelfCoverage
            isSpouseCoverage
            isTerminateCoverageOtherMecFoundAgreement
            lastUsedRoute
            planPremium
            planPremiumEffective
            processStatus
            renewEligibilityYearQuantity
            selectedMetalLevel
            selectedPlanIssuerID
            signatureType
            hasAnyMedicaidChipEligibleMembers
            hasAllMedicaidChipEligibleMembers
            applicant {
              id
              givenName
              familyName
              age
              isSmoker
              isPregnant
              middleName
              relation
              sex
              taxReturnFilingStatusType
              dob
              email
              ssn
              fullSSN
              contactID
              isRequestingCoverage
              homeAddress {
                street1
                street2
                city
                state
                zip
              }
              phone {
                number
              }
            }
            dmis {
              id
              status
              verificationType
              subType
              resolutionStatus
              resolveBy
              lastReviewed
              nonEscMecType
            }
            svis {
              id
              statusCode
              status
              resolveBy
              lastReviewed
            }
            ednDocuments {
              id
              dsrsIdentifier
              documentCreationDate
            }
            enrollmentGroups {
              id
              plan {
                id
                deductibles {
                  amount
                  type
                  familyCost
                  networkTier
                }
                benefits {
                  name
                  covered
                  costSharings {
                    coinsuranceOptions
                    coinsuranceRate
                    copayAmount
                    copayOptions
                    networkTier
                    csr
                    displayString
                  }
                  explanation
                  hasLimits
                  limitUnit
                  limitQuantity
                }
                name
                metalLevel
                type
                premium
                premiumWithCredit
                issuer {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    viewer(id: $userId) {
      id
      user {
        ...PersonFragment
      }
    }
  }
  ${FRAGMENT_PERSON}
`;

export const EDE_ENROLLMENT_ALL = gql`
  query HealthEnrollment2($input: GetHealthInsuranceEnrollmentInput!, $userId: ID) {
    viewer(id: $userId) {
      id
      user {
        ...PersonFragment
      }
    }
    viewerTwo(id: $userId) {
      id
    }
    healthInsuranceEnrollment(input: $input) {
      id
      status
      policyID
      applicationID
      isOptOutRenewal
      year
      pathwayType

      enrollmentType
      hasAssociatedDentalPlan
      actionsNeeded
      ...HealthApplicationLongFragment
      ...HealthPolicyFragmentLong
    }
  }
  ${FRAGMENT_HEALTH_APP_LONG}
  ${FRAGMENT_HEALTH_POLICY_LONG}
  ${FRAGMENT_PERSON}
`;

export const EDE_ENROLLMENT_PLANS = gql`
  query HealthEnrollment3($input: GetHealthInsuranceEnrollmentInput!) {
    healthInsuranceEnrollment(input: $input) {
      id
      status
      policyID
      applicationID
      isOptOutRenewal
      hasAssociatedDentalPlan
      year
      pathwayType

      enrollmentType
      sortedPlans {
        topPlans {
          catchRecommended {
            ...HealthPlanFieldsFragment
          }
        }
        plans {
          ...HealthPlanFieldsFragment
        }
      }
      ...HealthApplicationPlansFragment
      ...HealthPolicyFragmentLong
    }
  }
  ${FRAGMENT_HEALTH_APP_PLANS}
  ${FRAGMENT_HEALTH_POLICY_LONG}
  ${FRAGMENT_HEALTH_PLAN}
`;

export const EDE_ENROLLMENT_LONG = gql`
  query HealthEnrollment4($input: GetHealthInsuranceEnrollmentInput!, $userId: ID) {
    viewer(id: $userId) {
      id
      user {
        ...PersonFragment
      }
    }
    healthInsuranceEnrollment(input: $input) {
      id
      status
      policyID
      hasAssociatedDentalPlan
      applicationID
      isOptOutRenewal
      year
      pathwayType
      isOpenEnrollment
      enrollmentType
      sortedPlans {
        topPlans {
          catchRecommended {
            ...HealthPlanFieldsFragment
          }
        }
        plans {
          ...HealthPlanFieldsFragment
        }
      }
      ...HealthApplicationLongFragment
      ...HealthPolicyFragmentLong
    }
  }
  ${FRAGMENT_HEALTH_APP_LONG}
  ${FRAGMENT_HEALTH_POLICY_LONG}
  ${FRAGMENT_HEALTH_PLAN}
  ${FRAGMENT_PERSON}
`;

export const EDE_ENROLLMENT_ELIG = gql`
  query HealthApplicationEligibility($id: ID!, $userId: ID!) {
    viewerTwo(id: $userId) {
      id
      health {
        application(id: $id) {
          id
          isEligibilityDetermined
          isRequestingFinancialAssistance
          eligibilityLastFetched
          dmis {
            id
          }
          svis {
            id
          }
          eligibility {
            totalAptc
            csrLevel
            isMedicaid
            isChip
          }

          members {
            id
            # Eligibility
            initialEnrollmentEligibility {
              startDate
              endDate
              earliestQhpEffective
              latestQhpEffective
            }
            aptcEligibility {
              startDate
              endDate
              amount
            }
            csrEligibility {
              startDate
              endDate
              csrLevel
            }
            exchangeEligibility {
              startDate
              endDate
            }
            sepEligibility {
              startDate
              endDate
              earliestQhpEffective
              latestQhpEffective
              sepReason
              verificationPending
            }
            chipEligibility {
              startDate
              endDate
            }
            medicaidEligibility {
              startDate
              endDate
            }
          }

          applicant {
            id

            # Eligibility
            initialEnrollmentEligibility {
              startDate
              endDate
              earliestQhpEffective
              latestQhpEffective
            }
            aptcEligibility {
              startDate
              endDate
              amount
            }
            csrEligibility {
              startDate
              endDate
              csrLevel
            }
            exchangeEligibility {
              startDate
              endDate
            }
            sepEligibility {
              startDate
              endDate
              earliestQhpEffective
              latestQhpEffective
              sepReason
              verificationPending
            }
            chipEligibility {
              startDate
              endDate
            }
            medicaidEligibility {
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

export const TEST_USERS = gql`
  query TestUsers($input: ListHealthInsuranceEnrollmentsInput!) {
    healthEnrollments(input: $input) {
      pageInfo {
        totalItems
      }
      edges {
        id
        status
        year
        actionsNeeded
        policyID
        pathwayType
        isOpenEnrollment
        enrollmentType
        applicationID
        applicationStatus
        applicationCreatedOn
        lastUserUpdate
        lastUpdated
        lastUsedRoute
        coverageState
        insuranceApplicationIdentifier
        applicationOrigin
        # numDependentsRequestCoverage
        eligibilityLastFetched
        ownerID
        givenName
        familyName
        nickname
        email
        phoneNumber
        idProofResult
      }
    }
  }
`;

export const EDE_ENROLLMENTS = gql`
  query HealthEnrollments($input: ListHealthInsuranceEnrollmentsInput!) {
    healthEnrollments(input: $input) {
      pageInfo {
        totalItems
      }
      edges {
        id
        status
        year
        actionsNeeded
        policyID
        pathwayType
        isOpenEnrollment
        enrollmentType
        applicationID
        applicationStatus
        applicationCreatedOn
        applicationContext
        lastUserUpdate
        lastUpdated
        lastUsedRoute
        coverageState
        insuranceApplicationIdentifier
        applicationOrigin
        # numDependentsRequestCoverage
        eligibilityLastFetched
        isCatchNPN
        hasSubmittedPermissions
        ownerID
        givenName
        familyName
        nickname
        email
        avatarImage
        signupCode
        phoneNumber
        idProofResult
        task {
          id
          priority
          status
          assignee {
            id
            givenName
            familyName
            avatarImage
          }
        }
      }
    }
  }
`;

export const EDE_ENROLLMENT_FAST = gql`
  query HealthEnrollmentBoardSingleFast($id: ID!) {
    healthEnrollment(id: $id) {
      id
      status
      year
      actionsNeeded
      policyID
      pathwayType
      isOpenEnrollment
      enrollmentType
      applicationID
      applicationStatus
      applicationCreatedOn
      lastUserUpdate
      lastUpdated
      lastUsedRoute
      coverageState
      insuranceApplicationIdentifier
      applicationOrigin
      # numDependentsRequestCoverage
      eligibilityLastFetched
      ownerID
      givenName
      familyName
      nickname
      email
      avatarImage
      signupCode
      phoneNumber
      idProofResult
    }
  }
`;
