import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { size } from '../styles';

const StyledMetric = styled.div`
  flex: 1;
  color: ${p => p.color};
  padding: 0 0 0 16px;
  margin-right: 24px;
  display: block;
  max-width: 120px;
  position: relative;
  flex-shrink: 0;
  .metric-value {
    font-size: var(--h5);
    font-weight: 600;
    line-height: 1;
    height: var(--h5);
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' 1;
    letter-spacing: -1px;
  }
  .metric-name {
    font-size: var(--ffp);
    line-height: 1;
    font-weight: 500;
    color: var(--cTextSubtle);
    margin-top: 3px;
  }
  .metric-detail {
    font-size: var(--ffp);
    line-height: 1;
    font-weight: 600;
    opacity: 0.5;
    margin-top: 0px;
  }
`;

const StyledMetricChart = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 2px;
  border-radius: 2px;
  height: ${p => p.percent}%;
  background: var(--c-fg-0);
  animation-duration: 1.25s;
  animation-timing-function: var(--transitionCurve);
  animation-name: chart;
  @media (max-width: ${size.breakpoint}px) {
    top: 0;
    left: 0;
    height: 2px;
    bottom: auto;
    width: ${p => p.percent}%;
  }
`;

const StyledMetricChartC = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 2px;
  border-radius: 999px;
  height: 100%;
  background: var(--c-border-light);
  overflow: hidden;
`;

export const Metrics = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  margin: calc(-1 * var(--padding)) -24px var(--padding);
  padding: 0 0 0 48px;
  height: var(--navHeight);
  z-index: 999999000;
  border-bottom: 1px solid var(--c-border-light);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  > div {
    flex-grow: 1;
    flex-basis: 120px;
    flex-shrink: 0;
  }
`;

const Metric = ({ name, value, barValue = 0, background = 'var(--c-good-0)', detail, href, total }) => {
  return (
    <StyledMetric href={href} color={'var(--c-fg-0)'} background={background}>
      <div className="metric-value">
        <CountUp delay={0.15} end={value || 0} duration={1} useEasing preserveValue />
      </div>
      <div className="metric-name">{name}</div>
      {detail && <div className="metric-detail">{detail}</div>}
      <StyledMetricChartC background={background}>
        <StyledMetricChart percent={100 * ((barValue || 0) / total)} background={background} />
      </StyledMetricChartC>
    </StyledMetric>
  );
};

export default Metric;
