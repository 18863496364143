export const IDP_ENUM = {
  ACC: 'Success',
  RF1: 'Call Experian – frozen credit file',
  RF2: 'Call Experian –  incorrect answers',
  RF3: 'Limit Exceeded',
  RF4: 'Cannot Complete',
};

export const IDP_ENUM_NEW = [
  'VERIFIED',
  'PENDING_MANUAL_REVIEW',
  'PENDING_OFFLINE_VERIFICATION',
  'NEEDS_QUIZ',
  'LOCKED_OUT',
  'DENIED',
];

export const IDP_ENUM_FULL = [
  'UNKNOWN',
  'DENIED',
  'PENDING_PROVIDER',
  'NEEDS_QUIZ',
  'PENDING_OFFLINE_VERIFICATION',
  'NEEDS_DOCS',
  'SERVER_ISSUE',
  'STUCK',
  'INITIAL',
  'LOCKED_OUT',
  'PENDING_MANUAL_REVIEW',
];

export const makeHealthStatusSet = ({ eStatus, appStatus, policyStatus, paymentStatus, issues }) => {
  return {
    enrollment: eStatus?.toProperCase(),
    application: appStatus?.toProperCase(),
    policy: policyStatus?.toProperCase(),
    payment: paymentStatus?.replaceAll('POLICY_PAYMENT_STATUS_', '')?.toProperCase(),
    issues: issues,
  };
};

export const makeHealthStatus = (e, a) => {
  if (a?.status === 'RENEWAL') {
    return 'RENEWING';
  }
  if (a?.status === 'ENROLLMENT_STUCK') {
    return 'STUCK';
  }
  if (e?.policy?.policyStatus === 'ACTIVE_PENDING_PAYMENT') {
    return 'ENROLLED';
  }
  if ((e?.status === 'INITIAL_OEP' || e?.status === 'INITIAL_SEP') && a?.status === 'SUBMITTED')
    return 'ENROLLING';

  if (e?.policy?.policyStatus === 'CANCELLED') return 'CANCELLED';

  const statusMap = {
    PENDING: 'ACTION_NEEDED',
    INITIAL_OEP: 'APPLYING',
    INITIAL_SEP: 'APPLYING',
    PENDING_SUPPORT_TASK: 'ACTION_NEEDED',
  };
  return e?.status !== 'INITIAL_OEP' &&
    e?.status !== 'INITIAL_SEP' &&
    a?.status === 'SUBMITTED' &&
    e?.status !== 'PENDING_SUPPORT_TASK'
    ? 'ENROLLING'
    : statusMap[e?.status] || e?.status;
};

// organize drug or provider coverage for plans
export const getCoverage = ({ plan, coverage, requested }) => {
  if (!coverage || requested === 0) return 1;
  const covered = coverage?.filter(item => {
    if (item?.plan_id !== plan?.id) return false;
    if (item?.coverage === 'NotCovered') return false;
    else return true;
  });
  return covered?.length / parseFloat(requested);
};
// gets deductible based on family situation
export const getDeductible = ({ plan, people }) => {
  var ppl = people?.length;
  var maxFam = 0;
  const arr = plan?.deductibles
    ?.map((d, idx) => {
      var a = 0;
      if (d.family_cost === 'Family' && ppl > 1) maxFam = parseFloat(d.amount);
      if (d.family_cost === 'Family Per Person' && ppl > 1) a = parseFloat(d.amount);
      if (d.family_cost === 'Individual' && ppl <= 1) a = parseFloat(d.amount);
      return { amt: Math.max(maxFam, a), ...d };
    })
    .sort((a, b) => {
      return b.amt - a.amt;
    });
  return arr?.[0]?.amt;
};
// gets deductible based on family situation
export const getMoop = ({ plan, people }) => {
  var ppl = people?.length;
  var maxFam = 0;
  const arr = plan?.moops
    ?.map((d, idx) => {
      var a = 0;
      if (d.family_cost === 'Family' && ppl > 1) maxFam = parseFloat(d.amount);
      if (d.family_cost === 'Family Per Person' && ppl > 1) a = parseFloat(d.amount);
      if (d.family_cost === 'Individual' && ppl <= 1) a = parseFloat(d.amount);
      return { amt: Math.max(maxFam, a), ...d };
    })
    .sort((a, b) => {
      return b.amt - a.amt;
    });
  return arr?.[0]?.amt;
};

export const makeHealthCSV = plans => {
  var rows = [
    [
      'id',
      'name',
      'premium_w_credit',
      'metal_level',
      'type',
      'catch_score',
      'catch_score_budget',
      'catch_score_overall',
      'catch_score_comprehensive',
      'score_av',
      'score_av',
      'score_premium',
      'score_deductible',
      'score_moop',
      'score_geo',
      'score_medicines',
      'score_doctors',
      'deductible',
      'moop',
      'numPeople',
      'drugs',
      'docs',
      'csr',
      'income',
    ],
  ];

  const newRows = plans?.map((plan, idx) => {
    rows.push(
      rows[0].map((row, i) =>
        ['drugs', 'docs'].includes(row)
          ? plan[row]
              .map(e => e.name)
              ?.join(';')
              .toString()
          : plan[row]?.toString()?.replace(/,/g, ''),
      ),
    );
  });

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  //window.open(encodedUri);

  return encodedUri;
};

export const PHASES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const getSEP = person => {
  if (person?.isLostCoverageLast60Days) return { name: 'Lost Coverage', date: person?.whenLostCoverage };
  if (person?.isWillLoseCoverageNext60Days)
    return { name: 'Will Lose Coverage', date: person?.whenLostCoverage };
  if (person?.isReleasedFromIncarceration)
    return { name: 'Released from Incarceration', date: person?.whenReleasedFromIncarceration };
  if (person?.isMoved) return { name: 'Moved', date: person?.whenRelocated };
  if (person?.isGainDependent) return { name: 'Gained Dependent', date: person?.whenBecomeADependent };
};

export const OSCAR_ACTION_APP_STATUS = {
  CANCELED: 'Canceled',
  DELETED: 'Deleted',
  IN_PROGRESS: 'User Applying',
  EXPLORER: 'User Exploring',
  ENROLLED: 'Complete',
  PENDING_ENROLLMENT: 'Submitted to Carrier',
  ENROLLMENT_STUCK: 'Carrier Issue',
};

export const OSCAR_ACTION_TASK_STATUS = {
  TODO: 'Todo',
  IN_PROGRESS: 'In Progress',
  PENDING: 'Submitted to Carrier',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled',
};

export const parterHealthStatus = ({ taskStatus, appStatus, payStatus }) => {
  if (!!OSCAR_ACTION_APP_STATUS[appStatus]) return OSCAR_ACTION_APP_STATUS[appStatus];
  else if (!!OSCAR_ACTION_TASK_STATUS[taskStatus]) return OSCAR_ACTION_TASK_STATUS[taskStatus];
};

export const OSCAR_ACTION_STATUS = [
  {
    name: 'Todo',
    app: 'SUBMITTED',
    task: 'TODO',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'In Progress',
    app: 'SUBMITTED',
    task: 'IN_PROGRESS',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Submitted to Carrier',
    app: 'PENDING_ENROLLMENT',
    task: 'PENDING',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Carrier Issue',
    app: 'ENROLLMENT_STUCK',
    task: 'PENDING',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Complete',
    app: 'ENROLLED',
    task: 'COMPLETE',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Effectuated',
    app: 'ENROLLED',
    task: 'COMPLETE',
    payment: 'EFFECTUATED',
  },
  '–',
  {
    name: 'Canceled',
    app: 'CANCELED',
    task: 'CANCELED',
  },
];

export const Q_MAIN_VARS = {
  pagination: {
    pageNumber: 1,
    pageSize: 50,
  },
};

export const Q_MAIN_CONFIG = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
  pollInterval: 30000,
};

export const PAGINATION = Q_MAIN_VARS;
export const FETCH_CONFIG = Q_MAIN_CONFIG;

export const EDE_ROUTES_RENEW = ['intro', 'agreements', 'identity', 'triage', 'results'];

export const EDE_ROUTES = [
  'intro',
  'coverage-info',
  'privacy',
  'agreements',
  'household',
  'screening',
  '_',
  'pro-assistance',
  'identity',
  'id-info',
  'id-questions',
  'id-verify',
  'id-denied',
  '_',
  'application-import',
  'import',
  'triage',
  'confirm-applicants',
  'member-info',
  'member-questions',
  'immigration-details',
  'medicaid-denial',
  'member-income-list',
  'member-deductions-list',
  'member-income-details',
  'member-income-confirm',
  'member-income-discrepancy',
  'sep',
  'outside-household',
  'dependents-current-coverage',
  'member-medical-bills',
  'member-work-hours',
  'other-coverage',
  'details',
  'review',
  'agreements',
  '_',
  'results',
  'dmi',
  'split-eligibility',
  'plans',
  'plan',
  'aptc-agreement',
  'resubmit',
  'enroll',
  'success',
  'sep-out-of-date',
];

export const EDE_ROUTE_APP_START = 'application-import';
export const EDE_ROUTE_APP_START_IDX = EDE_ROUTES.indexOf(EDE_ROUTE_APP_START);
export const EDE_ROUTES_STARTING = EDE_ROUTES.slice(0, EDE_ROUTE_APP_START_IDX);
export const EDE_ROUTES_APPLYING = EDE_ROUTES.slice(EDE_ROUTE_APP_START_IDX, EDE_ROUTES.length);
export const PARTNER_ROUTES = [
  'partner',
  'partner-applicants',
  'partner-household',
  'risk',
  'usage',
  'drugs',
  'prescriptions',
  'plan',
  'partner-application',
  'partner-confirm',
];
