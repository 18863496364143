import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useRegisterActions, useKBar } from 'kbar';

import { KACTIONS } from '../kbar';
import { EnrollmentDebugView, IssuesPane } from 'src/web/inspectors';
import { SUBMIT_HEALTH } from 'src/web/data';
import {
  ensureHealth,
  fetchEligibility,
  fetchEligibilityAndResubmit,
  lookupApplications,
  submitEnrollment,
  syncHealth,
} from '../data/actions/user';

import {
  getSourceName,
  makeHealthStatus,
  makeHealthStatusSet,
  STATES,
  isSOEP,
  getSourceLogo,
  EDE_ROUTES,
  formatDate,
} from 'src/web/utility';
import {
  IRow,
  RowGroup,
  HealthPolicyBlock,
  EligSummary,
  People,
  IAction,
  IActionButtons,
  RouteProgress,
  ManualIdpModal,
  Banner,
  formatStatus,
  RowLabel,
  MenuDivider,
} from 'src/web/components';
import { formatPhone } from 'src/_utils';
import { DateFormat } from 'src/utils';
import { HealthApplicationModal } from '../components/modal/HealthApplicationModal';
import { GET_FULL_SSN } from 'src/data';

const SWrap = styled.div`
  border: 1px solid var(--c-border-light);
  padding: 8px 8px 4px;
  margin: 0 -8px;
  border-radius: 12px;
  height: 220px;
  display: flex;
  flex-direction: column;
  .inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const DELETE = gql`
  mutation PauseTrades2($input: HealthInsuranceEnrollmentInput!, $personID: ID!) {
    upsertHealthInsuranceEnrollmentAdmin(input: $input, personID: $personID) {
      insuranceEnrollment {
        id
        status
      }
    }
  }
`;

export const GET_EDN = gql`
  mutation GetEDN($personId: ID!, $dsrsIdentifier: String!) {
    getGeneratedDocumentURL(personID: $personId, dsrsIdentifier: $dsrsIdentifier)
  }
`;

const EDNAction = ({ edn, userId, mostRecent }) => {
  const [getEDN, { loading }] = useMutation(GET_EDN, {
    variables: {
      personId: userId,
      dsrsIdentifier: edn?.dsrsIdentifier,
    },
    onCompleted: data => {
      window.open(data.getGeneratedDocumentURL, '_blank');
    },
  });

  const date = mostRecent ? 'Latest' : formatDate({ date: edn?.documentCreationDate, length: 'FULL' });

  return (
    <IAction
      hasActions={!!edn}
      icon="Doc"
      iconColor={edn ? 'var(--c-fg-0)' : 'var(--c-fg-1)'}
      title={edn ? `${date} EDN` : 'EDN'}
      onPress={edn ? getEDN : null}
      text={loading ? 'Loading...' : edn ? 'Download' : 'EDN unavailable'}
    />
  );
};

const formatName = applicant => {
  const first = applicant?.givenName || '';
  const middle = applicant?.middleName || '';
  const last = applicant?.familyName || '';
  return `${first ? first + ' ' : ''}${middle ? middle + ' ' : ''}${last}`;
};

const formatAddress = address => {
  const street1 = address?.street1 || '';
  const street2 = address?.street2 || '';
  const city = address?.city || '';
  const state = address?.state || '';
  const zip = address?.zip || '';
  return `${street1 ? `${street1}, ` : ''}${street2 ? street2 + ', ' : ''}${city ? `${city}, ` : ''}${
    state ? `${state} ` : ''
  }${zip}`;
};

const formatSSN = applicant => {
  return applicant?.fullSSN === 'UNAUTHORIZED' ? applicant?.ssn : applicant?.fullSSN;
};

const EdeSharedInspector = ({ enrollment, application, user, singleUser, loading, pol }) => {
  const [debug, setDebug] = useState();
  const [proofing, setProofing] = useState();
  const [details, setDetails] = useState(false);
  const [show, setShow] = useState(false);
  const e = enrollment;
  const app = application || e?.healthApplication;
  const navigate = useNavigate();

  const appId = app?.id || e?.applicationID;
  const userId = user?.id;
  const { query } = useKBar();

  const [softDelete, { loading: deleting }] = useMutation(DELETE, {
    refetchQueries: 'HealthEnrollments',
    variables: {
      personID: user?.id || e?.task?.personReference?.id,
      input: {
        id: e?.id || enrollment?.id,
        isDeleted: true,
      },
    },
  });

  const [submitHealth] = useMutation(SUBMIT_HEALTH, {
    refetchQueries: ['HealthEnrollment', 'Enrollment'],
    variables: {
      id: app?.id,
      personId: user?.id,
    },
    onError: e => {
      alert(`ERROR: ${e}`);
      console.log(e);
    },
  });

  const st = makeHealthStatus(e, { status: e?.applicationStatus });
  const s = useMemo(
    () =>
      makeHealthStatusSet({
        eStatus: e?.status,
        appStatus: e?.applicationStatus,
        policyStatus: e?.policy?.policyStatus,
        paymentStatus: e?.policy?.paymentStatus,
        issues: app ? app?.dmis?.length + app?.svis?.length : [],
      }),
    [app, e],
  );

  useRegisterActions(
    [
      {
        ...KACTIONS.MANUAL_IDP,
        perform: () => setProofing(true),
      },
      {
        ...KACTIONS.HEALTH_ENSURE,
        perform: () => ensureHealth({ userId, appId }),
      },
      {
        ...KACTIONS.HEALTH_SUBMIT_APP,
        perform: submitHealth,
      },
      {
        ...KACTIONS.HEALTH_FETCH_ELIG,
        perform: () => fetchEligibility({ userId, appId }),
      },
      {
        ...KACTIONS.HEALTH_FETCH_RESUBMIT,
        perform: () => fetchEligibilityAndResubmit({ userId, appId }),
      },
      {
        ...KACTIONS.HEALTH_SUBMIT_ENROLLMENT,
        perform: () => submitEnrollment({ userId, appId }),
      },
      {
        ...KACTIONS.GOTO_USER,
        hide: !!singleUser,
        perform: () => navigate(`/users/${userId}/insurance/${appId}?type=uhealthapp`),
      },
      {
        ...KACTIONS.GOTO_ALL,
        hide: !singleUser,
        perform: () => navigate(`/tasks/health/${e?.id}?uid=${user?.id}&app=${appId}`),
      },
      {
        ...KACTIONS.HEALTH_SYNC,
        perform: () => syncHealth({ userId: userId, forceLink: true, forcePermissions: true }),
      },
      {
        ...KACTIONS.HEALTH_IMPORT,
        perform: () => lookupApplications({ userId: userId }),
      },
      { ...KACTIONS.DEBUG, perform: () => setDebug(!debug) },
      {
        ...KACTIONS.HEALTH_DELETE_ENROLLMENT,
        perform: () => {
          if (window.confirm('are you sure?')) softDelete();
        },
      },
    ],
    [appId, userId],
  );

  const ssnValue = formatSSN(app?.applicant);

  /**
   * @todo change L110 at end of OE
   */
  return (
    <>
      <RowGroup borderless>
        <IAction
          title={STATES[e?.coverageState]}
          logoSm
          logoFilter
          logo={`/img/states/dark/${STATES[e?.coverageState]?.toUpperCase()}.svg`}
          text2={
            <RowLabel
              text={
                app?.hasSubmittedPermissions === false
                  ? 'Not connected'
                  : app?.hasSubmittedPermissions === true
                  ? 'Connected'
                  : ''
              }
            />
          }
          text={`${e?.year} ${!!e?.isOpenEnrollment ? 'OEP' : isSOEP(e) ? 'SOEP' : 'OEP'} ${
            e?.applicationOrigin === 'IMPORT'
              ? 'Import'
              : e?.applicationOrigin === 'LINK_STRICT'
              ? 'Linked'
              : ''
          }`}
        />

        <IAction
          status={st}
          text2={
            // TODO we can remove 18946475 for PY25 -- that's andrew's old one
            <RowLabel
              text={
                !pol
                  ? ' '
                  : !!pol?.agentID
                  ? pol?.agentID === '20655395' || pol?.agentID === '18946575'
                    ? '✓ NPN'
                    : '✕ NPN'
                  : 'No NPN'
              }
            />
          }
        />
        {!singleUser && (
          <IAction
            circle
            logoSm
            title={getSourceName(e?.signupCode)}
            text={!e?.signupCode ? 'No signup code' : ''}
            text2={e?.signupCode}
            logo={getSourceLogo(e?.signupCode)}
            icon="App"
            iconColor="var(--c-fg-2)"
          />
        )}
        <RouteProgress
          route={e?.lastUsedRoute || app?.lastUsedRoute}
          time={e?.lastUserUpdate || app?.lastUserUpdate}
          routes={EDE_ROUTES}
          isRenew={e?.applicationStatus === 'RENEWAL'}
          userId={e?.ownerID || user?.id}
          appId={e?.applicationID || app?.id}
          task={e?.task}
        />
      </RowGroup>
      <RowGroup borderless>
        <IAction {...KACTIONS.VIEW_APP} onPress={() => setDetails(true)} />
        <MenuDivider invert />
        <IActionButtons>
          <IAction
            {...KACTIONS.MANUAL_IDP}
            text2={formatStatus(e?.idProofResult)}
            onPress={() => setProofing(true)}
          />

          <IAction {...KACTIONS.DEBUG} onPress={() => setDebug(true)} />

          {!singleUser ? (
            <IAction
              {...KACTIONS.GOTO_USER}
              onPress={() => navigate(`/users/${e?.ownerID}/insurance/${e?.applicationID}?type=uhealthapp`)}
            />
          ) : (
            <IAction
              {...KACTIONS.GOTO_ALL}
              onPress={() => navigate(`/tasks/health/${e?.id}?uid=${user?.id}`)}
            />
          )}

          <IAction {...KACTIONS.COMMAND} onPress={() => query.setVisualState('animating-in')} />
        </IActionButtons>
        <MenuDivider invert />

        <IAction
          pii
          icon="User"
          name={formatName(app?.applicant)}
          text={!app?.applicant?.givenName && !app?.applicant?.familyName && 'None'}
        />
        <IAction pii icon="Cake" name={app?.applicant?.dob} text={!app?.applicant?.dob && 'None'} />
        <IAction
          pii
          icon={show ? 'Unlock' : 'Lock'}
          name={ssnValue ? (show ? ssnValue : '••••••••••') : ''}
          onPress={() => setShow(!show)}
          text={!app?.applicant?.ssn && 'None'}
        />
        <IAction
          pii
          icon="Phone"
          name={formatPhone(app?.applicant?.phone?.number)}
          text={!app?.applicant?.phone && 'None'}
        />
        <IAction pii icon="Location" name={formatAddress(app?.applicant?.homeAddress)} />

        <br />

        <SWrap>
          <Banner
            tiny
            background={
              app?.isRequestingFinancialAssistance
                ? 'var(--c-border-light)'
                : loading
                ? 'transparent'
                : 'var(--c-bad-1)'
            }
            title={
              app?.isRequestingFinancialAssistance
                ? 'Applying for APTC'
                : loading
                ? 'Loading...'
                : 'Not applying for APTC'
            }
          />

          <EligSummary
            appId={appId}
            userId={userId}
            enrollment={e}
            inline
            isDet={!!e?.eligibilityLastFetched || !!app?.eligibilityLastFetched}
          />
          <div className="inner">
            <IAction
              {...KACTIONS.HEALTH_FETCH_ELIG}
              onPress={() => fetchEligibility({ userId: userId, appId: appId })}
            />

            {/* shows the most recent EDN */}
            <EDNAction loading={loading} userId={user?.id} edn={app?.ednDocuments?.[0]} mostRecent />
          </div>
        </SWrap>
      </RowGroup>

      {app?.ednDocuments?.length > 0 && (
        <RowGroup title="All EDNs" close>
          {app?.ednDocuments?.map(edn => (
            <EDNAction key={edn.id} userId={user?.id} edn={edn} />
          ))}
        </RowGroup>
      )}

      <RowGroup title="Technical Info" close>
        <IRow label="User ID" value={userId} type="mono" />
        <IRow label="Internal App ID" value={appId} type="mono" />
        <IRow hideUnset label="Internal Policy ID" value={e?.policyID} type="mono" />
        <IRow
          label="EDE App ID"
          value={app?.insuranceApplicationIdentifier || e?.insuranceApplicationIdentifier}
          helpText="CMS/EDE Application Identifier"
          type="mono"
        />
        <IRow
          label="EDE App Version"
          value={app?.applicationVersionNum}
          helpText="CMS/EDE Application Version"
          type="mono"
        />
        <IRow
          label="Context"
          value={app?.applicationContext || e?.applicationContext}
          helpText="FULL_APPLICATION | FASTLINK | SLASHER | CIC"
        />
        <IRow label="Origin" value={app?.applicationOrigin || e?.applicationOrigin} />
        <IRow label="Origin (Creation)" helpText="EDE creationOriginType" value={app?.creationOriginType} />
        <IRow
          label="Origin (Submission)"
          helpText="EDE submissionOriginType"
          value={app?.submissionOriginType}
        />
        <IRow
          label="Created"
          type="date"
          format={DateFormat.LONG}
          value={app?.createdOn || e?.applicationCreatedOn}
        />
        <IRow
          label="Updated"
          type="date"
          format={DateFormat.LONG}
          value={app?.lastUpdated || e?.lastUpdated}
        />
        <IRow
          label="Updated by User"
          type="date"
          format={DateFormat.LONG}
          value={app?.lastUserUpdate || e?.lastUserUpdate}
        />
        <IRow
          label="Elig last fetched"
          type="date"
          format={DateFormat.LONG}
          value={app?.eligibilityLastFetched || e?.eligibilityLastFetched}
        />
        <IRow hideUnset label="Enrollment Status" type="status" value={e?.status} />
        <IRow label="App Status" type="status" value={app?.status || e?.applicationStatus} />
        <IRow hideUnset label="Async Status" type="status" value={app?.asyncStatus} />
      </RowGroup>

      <RowGroup title="DMI/SVI" close>
        <IssuesPane app={app} />
      </RowGroup>

      {!!e?.policy && (
        <RowGroup
          close
          title="Policy"
          accessory={<People people={e?.members} detail="relation" popupPlacement="topRight" />}
        >
          <IRow label="Policy" type="status" value={s?.policy} />
          <IRow label="Payment" type="status" value={s?.payment} />
          <div>
            <HealthPolicyBlock
              compact
              inline
              name={e?.policy?.providerPlan?.name}
              grossPremium={e?.policy?.premium}
              netPremium={e?.policy?.premiumEffective}
              subsidy={e?.policy?.subsidy}
              issuer={e?.policy?.providerPlan?.issuer?.name}
              metal={e?.policy?.providerPlan?.metalLevel}
              type={e?.policy?.providerPlan?.type}
              moop={e?.policy?.providerPlan?.deductibles}
              benefits={e?.policy?.providerPlan?.benefits}
            />
          </div>
        </RowGroup>
      )}

      {e?.policy?.providerPlan ? (
        <></>
      ) : (
        <RowGroup title="Enrollment Groups" close>
          {app?.enrollmentGroups?.map((group, idx) => (
            <RowGroup
              subtle
              key={group?.id}
              title={`Pending Group ${idx + 1}`}
              accessory={<People people={group?.members} detail="relation" popupPlacement="topRight" />}
            >
              {!!group?.plan?.id ? (
                <HealthPolicyBlock
                  compact
                  inline
                  name={group?.plan?.name}
                  grossPremium={group?.plan?.premium}
                  netPremium={group?.plan?.premiumWithCredit}
                  subsidy={group?.plan?.subsidy}
                  issuer={group?.plan?.issuer?.name}
                  metal={group?.plan?.metalLevel}
                  type={group?.plan?.type}
                  moop={group?.plan?.deductibles}
                  benefits={group?.plan?.benefits}
                />
              ) : (
                <div style={{ opacity: 0.5, fontWeight: 500, marginBottom: 24, color: 'var(--c-fg-2)' }}>
                  No plan chosen
                </div>
              )}
            </RowGroup>
          ))}
        </RowGroup>
      )}

      {app?.plan?.name && (
        <RowGroup title="Explorer" subtle>
          <HealthPolicyBlock
            compact
            inline
            name={app?.plan?.name}
            grossPremium={app?.plan?.premium}
            netPremium={app?.plan?.premiumWithCredit}
            issuer={app?.plan?.issuer?.name}
            metal={app?.plan?.metalLevel}
            type={app?.plan?.type}
            moop={app?.plan?.deductibles}
            benefits={app?.plan?.benefits}
          />
        </RowGroup>
      )}

      {e?.policy && (
        <RowGroup title="Payment" close>
          <IAction status={s?.payment} />
          <IRow label="Policy #" type="mono" value={e?.policy?.policyNumber || 'None'} />
          <IRow label="Exchange #" type="mono" value={e?.policy?.exchangePolicyNumber || 'None'} />{' '}
        </RowGroup>
      )}

      {!!debug && (
        <EnrollmentDebugView eobj={{ ...e, app }} e={e?.id} u={e?.ownerID} onClose={() => setDebug(false)} />
      )}
      {!!proofing && (
        <ManualIdpModal
          appId={app?.id}
          userId={e?.ownerID}
          task={e?.task}
          onRequestClose={() => setProofing(false)}
          info={{
            ...e,

            ssn: e?.healthApplication?.applicant?.ssn,
            dob: e?.healthApplication?.applicant?.dob,
          }}
        />
      )}
      <HealthApplicationModal
        appId={app?.id}
        personId={e?.ownerID}
        isOpen={details}
        onRequestClose={() => setDetails(false)}
      />
    </>
  );
};

export default EdeSharedInspector;
