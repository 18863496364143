import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import FlatList from 'flatlist-react';

import { EdeExportModal } from './utils/edeExport';
import { EDE_ENROLLMENTS } from 'src/web/data';
import { PAGINATION, STATES } from 'src/web/utility';
import { CODES } from 'src/web/utility/general';
import {
  Metric,
  Metrics,
  MenuItem,
  MenuDivider,
  Filter,
  Filters,
  HRow,
  Carousel,
  Status,
  Scroll,
  Toolbar,
  ToolbarButton,
} from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';

const PoliciesPage = () => {
  const { selectedId, setSelected } = useInspector();
  const [exporting, setExporting] = useState();
  const [state, setState] = useState();

  const [product, setProduct] = useState(['HEALTH_INSURANCE']);

  const [code, setCode] = useState();
  const [year, setYear] = useState(2025);
  const [npn, setNpn] = useState();
  const [connection, setConnection] = useState();

  const filters = {
    state,
    signupCodes: CODES[code]?.codes,
    enrollmentTypes: product,
    year,
    isCatchNPN: !!npn ? (npn === 'Catch' ? true : false) : null,
    hasSubmittedPermissions: connection === 'Connected' ? true : connection === 'Disconnected' ? false : null,
    ...PAGINATION,
  };

  const { data: enrolled2 } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: 20000,
    variables: {
      input: {
        healthPolicyStatuses: ['ACTIVE'],
        ...filters,
      },
    },
  });

  const { data: enrolled } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: 20000,
    variables: {
      input: {
        healthPolicyStatuses: ['PENDING_START_DATE', 'PENDING', 'ACTIVE_PENDING_PAYMENT'],
        ...filters,
      },
    },
  });

  const { data: terminated } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: 20000,
    variables: {
      input: {
        healthPolicyStatuses: ['CANCELLED', 'TERMINATED'],
        ...filters,
      },
    },
  });

  const { data: expired } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: 20000,
    variables: {
      input: {
        healthPolicyStatuses: ['EXPIRED'],
        ...filters,
      },
    },
  });

  const BUCKETS = [
    {
      name: 'Expired',
      metricColor: 'var(--c-fg-1)',
      accentColor: 'var(--c-border)',
      items: expired,
    },
    {
      name: 'Terminated',
      metricColor: 'var(--c-bad-0)',
      accentColor: 'var(--c-bad-1)',
      items: terminated,
    },
    {
      name: 'Active',
      metricColor: 'var(--c-cerise-0)',
      accentColor: 'var(--c-cerise-1)',
      items: enrolled2,
    },
    {
      name: 'Pending Start',
      metricColor: 'var(--c-cerise-0)',
      accentColor: 'var(--c-cerise-1)',
      items: enrolled,
    },
  ];

  const total = BUCKETS.reduce((acc, { count }) => acc + count, 0);
  var _t = 0;
  var _totals = [...BUCKETS].reverse()?.map(bucket => {
    const n = bucket?.count + _t;
    _t = n;
    return n;
  });

  return (
    <>
      <Toolbar title="Policies" subtitle="EDE" icon="Health" overInspector>
        <Filters>
          <Filter
            icon={product?.[0] === 'DENTAL_INSURANCE' ? 'Dental' : 'Medical'}
            text={product?.[0]?.replace('_INSURANCE', '')?.toProperCase()}
            menuContent={
              <>
                <MenuItem
                  text="Any type"
                  onPress={() => {
                    setProduct(null);
                  }}
                />
                <MenuDivider />
                {['HEALTH_INSURANCE', 'DENTAL_INSURANCE'].map(y => (
                  <MenuItem
                    iconic={y === 'DENTAL_INSURANCE' ? 'Dental' : 'Medical'}
                    key={y}
                    text={y?.replace('_INSURANCE', '')?.toProperCase()}
                    onPress={() => {
                      setProduct([y]);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            icon="Clock"
            active={!!year}
            text={year ? `PY${year?.toString().slice(2)}` : 'Year'}
            menuContent={
              <>
                <MenuItem
                  text="All Years"
                  onPress={() => {
                    setYear();
                  }}
                />
                <MenuDivider />
                {[2025, 2024, 2023, 2022].map(y => (
                  <MenuItem
                    key={y}
                    text={y}
                    onPress={() => {
                      setYear(y);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            icon="Map"
            active={!!state}
            text={STATES[state] || 'State'}
            menuContent={
              <>
                <MenuItem
                  icon={`/img/states/dark/USA.svg`}
                  text="All States"
                  onPress={() => {
                    setState();
                  }}
                />
                <MenuDivider />
                {Object.keys(STATES).map(s => (
                  <MenuItem
                    key={s}
                    icon={`/img/states/dark/${STATES[s]?.toUpperCase()}.svg`}
                    text={STATES[s]}
                    onPress={() => {
                      setState(s);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            icon="App"
            active={!!code}
            text={code || 'Partner'}
            menuContent={
              <>
                <MenuItem
                  text="Anywhere"
                  onPress={() => {
                    setCode(null);
                  }}
                />
                <MenuDivider />
                {Object.keys(CODES).map(k => (
                  <MenuItem
                    fillIcon
                    icon={`https://s.catch.co/img/sources/${CODES[k]?.icon}.png`}
                    key={k}
                    text={k}
                    onPress={() => {
                      setCode(k);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            icon={'Dollar'}
            active={!!npn}
            text={npn || 'NPN'}
            menuContent={
              <>
                <MenuItem
                  text="Any NPN"
                  onPress={() => {
                    setNpn(null);
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text="Catch"
                  onPress={() => {
                    setNpn('Catch');
                  }}
                />
                <MenuItem
                  text={'Other'}
                  onPress={() => {
                    setNpn('Other');
                  }}
                />
              </>
            }
          />

          <Filter
            icon={'Bank'}
            active={!!connection}
            text={connection || 'EDE'}
            menuContent={
              <>
                <MenuItem
                  text={'Any status'}
                  onPress={() => {
                    setConnection(null);
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text={'EDE Connected'}
                  onPress={() => {
                    setConnection('Connected');
                  }}
                />
                <MenuItem
                  text={'EDE Disconnected'}
                  onPress={() => {
                    setConnection('Disconnected');
                  }}
                />
              </>
            }
          />
        </Filters>
      </Toolbar>
      <Scroll toolbar large>
        <Metrics>
          {BUCKETS?.reverse()?.map((bucket, idx) => (
            <Metric
              key={bucket.name}
              href={`#${bucket.name?.toLowerCase()}`}
              background={bucket.metricColor}
              name={bucket.name}
              chart={bucket.metricChart}
              value={bucket.items?.healthEnrollments?.pageInfo?.totalItems}
              barValue={_totals[_totals?.length - idx - 1]}
              total={total}
              invert={!!bucket.metricInvert}
            />
          ))}
        </Metrics>

        <Carousel>
          {BUCKETS?.map((bucket, idx) => (
            <div className="kanban-col-wrap" key={bucket.name}>
              <div className="kanban-status flex-h">
                <Status status={bucket.name} />
              </div>
              <div className="kanban-col">
                <FlatList
                  renderWhenEmpty={() => <></>}
                  list={bucket.items?.healthEnrollments?.edges}
                  renderItem={(item, idx) => (
                    <HRow
                      accent={bucket.accentColor}
                      showOwner
                      standalone
                      setSelected={() => {
                        setSelected(item?.policyID, {
                          type: SelectedType.POLICY,
                          uid: item?.ownerID,
                        });
                      }}
                      selected={!!selectedId && item?.id === selectedId}
                      item={item}
                      key={item?.id}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </Scroll>
      {!!exporting && <EdeExportModal onCompleted={() => setExporting(false)} />}
    </>
  );
};

export default PoliciesPage;
