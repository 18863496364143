import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import FlatList from 'flatlist-react';

import { EdeExportModal } from './utils/edeExport';
import { EDE_ENROLLMENTS } from 'src/web/data';
import { IDP_ENUM_FULL, PAGINATION, IDP_ENUM_NEW, EDE_ROUTES, STATES } from 'src/web/utility';
import { CODES } from 'src/web/utility/general';
import {
  Metric,
  Metrics,
  MenuItem,
  MenuDivider,
  Filter,
  Filters,
  HRow,
  Carousel,
  Status,
  Scroll,
  Toolbar,
  ToolbarButton,
  formatStatus,
  Banner,
} from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';

const POLL_FREQ = 10000;

const EDEPage = () => {
  const { selectedId, setSelected } = useInspector();
  const [exporting, setExporting] = useState();

  const [state, setState] = useState();
  const [product, setProduct] = useState(['HEALTH_INSURANCE']);
  const [idp, setIdp] = useState();
  const [code, setCode] = useState();
  const [year, setYear] = useState(2025);
  const [context, setContext] = useState();
  const [todo, setTodo] = useState();
  const [npn, setNpn] = useState();
  const [page, setPage] = useState();
  const [connection, setConnection] = useState();

  const filters = {
    state,
    signupCodes: CODES[code]?.codes,
    enrollmentTypes: product,
    year,
    idProofResults: idp ? [idp] : null,
    applicationContexts: context ? [context] : null,
    lastUsedRoutes: page ? [page] : undefined,
    hasSubmittedPermissions: connection === 'Connected' ? true : connection === 'Disconnected' ? false : null,
    actionsNeeded: todo
      ? todo === 'DMI/SVI'
        ? ['ACTION_DMI', 'ACTION_SVI']
        : todo === 'Payment'
        ? ['ACTION_PAYMENT']
        : null
      : null,
    ...PAGINATION,
  };

  const { data: enrolled } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: POLL_FREQ,
    variables: {
      input: {
        isCatchNPN: npn === 'Catch' ? true : npn === 'Other' ? false : null,
        healthPolicyStatuses: ['ACTIVE_PENDING_PAYMENT', 'PENDING_START_DATE', 'ACTIVE'],
        ...filters,
      },
    },
  });

  const { data: submitted } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: POLL_FREQ,
    variables: {
      input: {
        healthApplicationStatuses: ['SUBMITTED', 'ENROLLMENT_STUCK'],
        ...filters,
      },
    },
  });

  const { data: applying } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: POLL_FREQ,
    variables: {
      input: {
        healthApplicationStatuses: ['IN_PROGRESS', 'DRAFT', 'EXPLORER'],
        ...filters,
        idProofResults: !idp || idp === 'VERIFIED' ? ['VERIFIED'] : ['UNKNOWN'],
      },
    },
  });

  const { data: screening } = useQuery(EDE_ENROLLMENTS, {
    pollInterval: POLL_FREQ,
    variables: {
      input: {
        healthApplicationStatuses: ['IN_PROGRESS', 'DRAFT', 'EXPLORER'],
        ...filters,
        idProofResults:
          !!idp && idp !== 'VERIFIED' ? [idp] : idp === 'VERIFIED' ? ['UNKNOWN'] : IDP_ENUM_FULL,
      },
    },
  });

  const BUCKETS = [
    {
      name: 'Enrolled',
      metricColor: 'var(--c-cerise-0)',
      accentColor: 'var(--c-cerise-1)',
      items: enrolled,
      count: enrolled?.healthEnrollments?.pageInfo?.totalItems,
      icon: 'Done',
    },
    {
      name: 'Submitted',
      metricColor: 'var(--c-lime-0)',
      accentColor: 'var(--c-lime-1)',
      items: submitted,
      count: submitted?.healthEnrollments?.pageInfo?.totalItems,
      icon: 'Send',
    },
    {
      name: 'Applying',
      metricColor: 'var(--c-orange-0)',
      accentColor: 'var(--c-orange-1)',
      items: applying,
      count: applying?.healthEnrollments?.pageInfo?.totalItems,
      icon: 'Edit',
    },
    {
      name: 'Screening',
      metricColor: 'var(--c-red-0)',
      accentColor: 'var(--c-red-1)',
      items: screening,
      count: screening?.healthEnrollments?.pageInfo?.totalItems,
      icon: 'Question',
    },
  ];

  const total = BUCKETS.reduce((acc, { count }) => acc + count, 0);

  var _t = 0;
  var _totals = [...BUCKETS].reverse()?.map(bucket => {
    const n = bucket?.count + _t;
    _t = n;
    return n;
  });

  return (
    <>
      <Toolbar title="Applications" subtitle="EDE" icon="Health" overInspector>
        <Filters>
          <Filter
            title="Type"
            icon={product?.[0] === 'DENTAL_INSURANCE' ? 'Dental' : 'Medical'}
            text={product?.[0]?.replace('_INSURANCE', '')?.toProperCase()}
            menuContent={
              <>
                <MenuItem
                  text="Any type"
                  onPress={() => {
                    setProduct(null);
                  }}
                />
                <MenuDivider />
                {['HEALTH_INSURANCE', 'DENTAL_INSURANCE'].map(y => (
                  <MenuItem
                    iconic={y === 'DENTAL_INSURANCE' ? 'Dental' : 'Medical'}
                    key={y}
                    text={y?.replace('_INSURANCE', '')?.toProperCase()}
                    onPress={() => {
                      setProduct([y]);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            type="Plan Year"
            icon="Clock"
            active={!!year}
            text={year ? `PY${year?.toString().slice(2)}` : 'Year'}
            menuContent={
              <>
                <MenuItem
                  text="All years"
                  onPress={() => {
                    setYear();
                  }}
                />
                <MenuDivider />
                {[2025, 2024, 2023, 2022].map(y => (
                  <MenuItem
                    key={y}
                    text={y}
                    onPress={() => {
                      setYear(y);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            type="Identity Proofing"
            icon="Fingerprint"
            active={!!idp}
            text={formatStatus(idp) || 'IDP'}
            menuContent={
              <>
                <MenuItem
                  text={<Status status="Any" />}
                  onPress={() => {
                    setIdp();
                  }}
                />
                <MenuDivider />
                {IDP_ENUM_NEW?.map(s => (
                  <MenuItem
                    key={s}
                    text={<Status status={s} />}
                    onPress={() => {
                      setIdp(s);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            title="Coverage State"
            icon="Map"
            active={!!state}
            text={STATES[state] || 'State'}
            menuContent={
              <>
                <MenuItem
                  icon={`/img/states/dark/USA.svg`}
                  text="All States"
                  onPress={() => {
                    setState();
                  }}
                />
                <MenuDivider />
                {Object.entries(STATES).map(([code, name]) => (
                  <MenuItem
                    key={code}
                    icon={`/img/states/dark/${name?.toUpperCase()}.svg`}
                    text={name}
                    onPress={() => {
                      setState(code);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            title="Partner"
            icon="App"
            active={!!code}
            text={code || 'Partner'}
            menuContent={
              <>
                <MenuItem
                  text="Any"
                  onPress={() => {
                    setCode(null);
                  }}
                />
                <MenuDivider />
                {Object.keys(CODES)?.map(k => (
                  <MenuItem
                    fillIcon
                    icon={`https://s.catch.co/img/sources/${CODES[k]?.icon}.png`}
                    key={k}
                    text={k}
                    onPress={() => {
                      setCode(k);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            title="Last Route"
            icon="Location"
            active={!!page}
            text={page?.replaceAll('-', ' ')?.toProperCase()}
            menuContent={
              <>
                <MenuItem
                  text="Any page"
                  onPress={() => {
                    setPage();
                  }}
                />
                <MenuDivider />
                {EDE_ROUTES.slice(EDE_ROUTES.indexOf('application-import')).map(y =>
                  y === '_' ? (
                    <MenuDivider />
                  ) : (
                    <MenuItem
                      key={y}
                      text={y?.replaceAll('-', ' ')?.toProperCase()}
                      onPress={() => {
                        setPage(y);
                      }}
                    />
                  ),
                )}
              </>
            }
          />

          <Filter
            title="Context"
            icon="Sign"
            active={!!context}
            text={context
              ?.replace('HEALTH_', '')
              ?.toProperCase()
              ?.replace('Cic', 'CIC')
              ?.replace('Ebp', 'EBP')}
            menuContent={
              <>
                <MenuItem
                  text="Any context"
                  onPress={() => {
                    setContext();
                  }}
                />
                <MenuDivider />
                {['FULL_APPLICATION', 'HEALTH_RENEW', 'FASTLINK', 'CIC', 'SLASHER', 'EBP'].map(s => (
                  <MenuItem
                    key={s}
                    text={s
                      ?.replace('HEALTH_', '')
                      ?.toProperCase()
                      ?.replace('Cic', 'CIC')
                      ?.replace('Ebp', 'EBP')}
                    onPress={() => {
                      setContext(s);
                    }}
                  />
                ))}
              </>
            }
          />

          <Filter
            title="Actions Needed"
            icon="Warning"
            active={!!todo}
            text={todo}
            menuContent={
              <>
                <MenuItem
                  text="Any status"
                  onPress={() => {
                    setTodo(null);
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text={'Has DMI/SVI'}
                  onPress={() => {
                    setTodo('DMI/SVI');
                  }}
                />
                <MenuItem
                  text={'Needs Binder Payment'}
                  onPress={() => {
                    setTodo('Payment');
                  }}
                />
              </>
            }
          />

          <Filter
            title="Broker"
            icon={'Dollar'}
            active={!!npn}
            text={npn}
            menuContent={
              <>
                <MenuItem
                  text="Any NPN"
                  onPress={() => {
                    setNpn(null);
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text="Catch"
                  onPress={() => {
                    setNpn('Catch');
                  }}
                />
                <MenuItem
                  text={'Other'}
                  onPress={() => {
                    setNpn('Other');
                  }}
                />
              </>
            }
          />

          <Filter
            title="EDE Connection"
            icon={'Bank'}
            active={!!connection}
            text={connection}
            menuContent={
              <>
                <MenuItem
                  text={'Any status'}
                  onPress={() => {
                    setConnection(null);
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text={'EDE Connected'}
                  onPress={() => {
                    setConnection('Connected');
                  }}
                />
                <MenuItem
                  text={'EDE Disconnected'}
                  onPress={() => {
                    setConnection('Disconnected');
                  }}
                />
              </>
            }
          />
        </Filters>
      </Toolbar>
      <Scroll toolbar large>
        <Metrics>
          {BUCKETS?.reverse()?.map((bucket, idx) => (
            <Metric
              key={bucket.name}
              href={`#${bucket.name?.toLowerCase()}`}
              background={bucket.metricColor}
              name={bucket.name}
              chart={bucket.metricChart}
              value={bucket.count}
              barValue={_totals[_totals?.length - idx - 1]}
              total={total}
              invert={!!bucket.metricInvert}
            />
          ))}
        </Metrics>

        <Carousel>
          {BUCKETS?.map((bucket, idx) => (
            <div className="kanban-col-wrap" key={bucket.name}>
              <div className="kanban-status flex-h">
                <Status status={bucket.name} iconic={bucket.icon} />
              </div>
              <div className="kanban-col">
                <FlatList
                  renderWhenEmpty={() => <></>}
                  list={
                    bucket.items?.healthEnrollments?.edges ||
                    Array.from({ length: 50 })?.map((i, idx) => ({ placeholder: true }))
                  }
                  renderItem={(item, idx) => (
                    <HRow
                      accent={bucket.accentColor}
                      showOwner
                      standalone
                      setSelected={() =>
                        setSelected(item?.applicationID, {
                          type: SelectedType.APP,
                          uid: item?.ownerID,
                        })
                      }
                      selected={!!selectedId && item?.id === selectedId}
                      item={item}
                      key={item?.id || idx}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </Carousel>
        {year < 2022 && (
          <div style={{ position: 'absolute', bottom: 24 }}>
            <Banner
              title="These enrollments are no longer updated"
              subtitle="Only the current, previous, and next plan years receive updates"
            />
          </div>
        )}
      </Scroll>
      {!!exporting && <EdeExportModal onCompleted={() => setExporting(false)} />}
    </>
  );
};

export default EDEPage;
